import React from "react";
import OwlCarousel from "react-owl-carousel";
import LazyLoad from 'react-lazyload';

export default function BrandCarousel({ hasBg }) {
  const options = {
    autoplay: true,
    loop: true,
    margin: 15,
    dots: true,
    slidetransition: "linear",
    autoplayTimeout: 4500,
    autoplayHoverPause: true,
    autoplaySpeed: 4500,
    responsive: {
      0: {
        items: 2,
      },
      500: {
        items: 3,
      },
      600: {
        items: 4,
      },
      800: {
        items: 5,
      },
      1200: {
        items: 6,
      },
    },
  };

  return (
    <>
      <section
        className={`client-section ptb-100 ${hasBg ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                {hasBg ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Our Trusted Clients
                  </span>
                ) : (
                  ""
                )}
                <h2>Empresas con las que trabajamos</h2>
                <p className="lead">
                  Nuestras plataformas se integran con los grandes portales
                  inmobiliarios del sector, orgullosos de nuestros logros estos
                  son algunas de las empresas donde posicionamos nuestra marca.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-12">
              <OwlCarousel
                className="owl-carousel owl-theme clients-carousel dot-indicator"
                {...options}
              >
                <div className="item single-client">
                  <LazyLoad height={300} offset={88}>
                    <img
                      src="assets/logo/logo_idealista.jpg"
                      alt="client logo"
                      className="client-img"
                    />
                  </LazyLoad>
                </div>

                <div className="item single-client">
                  <LazyLoad height={300} offset={88}>
                    <img
                      src="assets/logo/logo_fotocasa-300x88.jpg"
                      alt="client logo"
                      className="client-img"
                    />
                  </LazyLoad>
                </div>
                <div className="item single-client">
                  <LazyLoad height={300} offset={88}>
                    <img
                      src="assets/logo/logo_pisos-com-300x88.jpg"
                      alt="client logo"
                      className="client-img"
                    />
                  </LazyLoad>
                </div>
                <div className="item single-client">
                  <LazyLoad height={300} offset={88}>
                    <img
                      src="assets/logo/logo_habitaclia-300x88.jpg"
                      alt="client logo"
                      className="client-img"
                    />
                  </LazyLoad>
                </div>
                <div className="item single-client">
                  <LazyLoad height={300} offset={88}>
                    <img
                      src="assets/logo/logo_kyero-logo-400x118.jpg"
                      alt="client logo"
                      className="client-img"
                    />
                  </LazyLoad>
                </div>
                <div className="item single-client">
                  <LazyLoad height={300} offset={88}>
                    <img
                      src="assets/logo/logo_tucasa-300x88.jpg"
                      alt="client logo"
                      className="client-img"
                    />
                  </LazyLoad>
                </div>
                <div className="item single-client">
                  <LazyLoad height={300} offset={88}>
                    <img
                      src="assets/logo/logo_yaencontre-300x88.jpg"
                      alt="client logo"
                      className="client-img"
                    />
                  </LazyLoad>
                </div>
                <div className="item single-client">
                  <LazyLoad height={300} offset={88}>
                    <img
                      src="assets/logo/logo_Logo_terrenos_es.jpg"
                      alt="client logo"
                      className="client-img"
                    />
                  </LazyLoad>
                </div>
                <div className="item single-client">
                  <LazyLoad height={300} offset={88}>
                    <img
                      src="assets/logo/logo_Logo-Indomio-400x118.jpg"
                      alt="client logo"
                      className="client-img"
                    />
                  </LazyLoad>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
