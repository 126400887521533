import React from "react";
import AppRoutes from "./routes/Routes";
// import { HelmetProvider } from "react-helmet-async";

const App = () => {
  return (
    <div>
      {/* <HelmetProvider> */}
        <AppRoutes />
      {/* </HelmetProvider> */}
    </div>
  );
};

export default App;
