import React from "react";
import LazyLoad from 'react-lazyload';

export default function Team({ hasTitle, bgColor }) {
  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                {hasTitle ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Nuestro equipo
                  </span>
                ) : (
                  ""
                )}
                <h2>Nuestro Equipo de Profesionales</h2>
                <p className="lead">
                  En PisosValencia.net, hemos creado un equipo
                  multidisciplinario único, formado por Consultores
                  Inmobiliarios de alto nivel, Expertos en Marketing Digital
                  innovador, Ingenieros en IT especializados en Bienes Raices y
                  Vendedores excepcionales. Juntos, logramos el equilibrio
                  perfecto entre servicio personalizado, tecnología de punta y
                  estrategias efectivas para transformar tus objetivos en
                  resultados concretos
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <LazyLoad height={200} offset={100}>
                    <img
                      src="assets/team/team-nico.jpg"
                      alt="Nicolas Marquez"
                      className="img-fluid rounded-circle"
                    />
                  </LazyLoad>
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Nicolas Marquez</h5>
                  <h6>Asesor Inmobiliario Experto </h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/profile.php?id=61553286433192">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li> */}
                    <li className="list-inline-item">
                      <a href="https://instagram.com/nicolasmarquez.ok">
                        <span className="ti-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <LazyLoad height={200} offset={100}>
                    <img
                      src="assets/team/team-damian.jpg"
                      alt="Team Members"
                      className="img-fluid rounded-circle"
                    />
                  </LazyLoad>
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Damian Frieri</h5>
                  <h6>Ingeniero IT</h6>
                  <h6>Especialista en Proptech</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/profile.php?id=100078127983364">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://instagram.com/damianfrieri">
                        <span className="ti-instagram"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://gitlab.com/damianfr">
                        <span className="ti-github"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <LazyLoad height={200} offset={100}>
                    <img
                      src="assets/team/team-gaston.jpg"
                      alt="Team Members"
                      className="img-fluid rounded-circle"
                    />
                  </LazyLoad>
                </div>

                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Gaston Masucco</h5>
                  <h6>Disenador Senior</h6>
                  <h6>Experto Audivisual</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://instagram.com/keep_moving.arg">
                        <span className="ti-instagram"></span>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <LazyLoad height={200} offset={100}>
                    <img
                      src="assets/img/team-3.jpg"
                      alt="Team Members"
                      className="img-fluid rounded-circle"
                    />
                  </LazyLoad>
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Edna Carrazco</h5>
                  <h6>Marketing Manager</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-instagram"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
