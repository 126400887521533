import React from "react";
import Layout from "../../components/layout/Layout";
import Navbar from "../../components/layout/header/Navbar";
import HeroSix from "./HeroSix";
import Price2 from "../../components/prices/Price2";
import Subsribe from "../../components/newsletter/Subsribe";
import Footer from "../../components/layout/footer/Footer";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import FeatureImgFive from "../../components/features/FeatureImgFive";
import FeatureImgFive2 from "../../components/features/FeatureImgFive2";
import OurTeam from '../../components/team/Team'

export default function HomeSix() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroSix />
      <FeatureImgFive2></FeatureImgFive2>
      <FeatureImgFive></FeatureImgFive>
      <Price2 hasBg/>
      <OurTeam></OurTeam>
      <BrandCarousel/>
      <Subsribe />
      <Footer space />
    </Layout>
  );
}
