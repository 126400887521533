import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import FormularioPaginaPrincipal from "../../components/modal/FormularioPaginaPrincipal";

export default function HeroSix() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
        <section
          className="hero-section background-img pt-100"
          style={{
            background:
              "url('assets/img/hero-bg-4.jpg')no-repeat center center / cover)",
            marginBottom: "40px",
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-left position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                  <h1 className="text-white">Vendemos tu piso</h1>
                  <h1 className="text-white">en 10 dias.</h1>
                  <h3 className="text-white">
                    Solicitá una valoración gratuita.
                  </h3>
                  <p className="lead">
                    Acelera las ventas de pisos, desarrollos y solares con
                    estrategias hechas 100% para bienes raíces con el mejor
                    equipo y desarrollo tecnológico, vendemos tu propiedad en
                    menos tiempo y al mejor valor.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-lg-5">
                <div className="" style={{ marginBottom: "50px" }}>
                  {/* <ResponsiveDialog open={true}/> */}
                  <FormularioPaginaPrincipal
                    title={"Solicitá una valoración gratuita"}
                    titleButton={"Enviar"}
                  />
                </div>
              </div>
            </div>
            <ModalVideo
              channel="youtube"
              isOpen={isOpen}
              videoId="9No-FiEInLA"
              onClose={() => setOpen(false)}
            />
          </div>
          <div className="section-shape position-absolute">
            <img src="assets/img/waves.svg" alt="shape" />
          </div>
        </section>
    </>
  );
}
