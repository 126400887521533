import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ResponsiveDialog from "../../modal/ResponsiveDialog";
import LazyLoad from "react-lazyload";

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${
            darkBg ? "bg-transparent" : "custom-nav white-bg"
          } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <LazyLoad height={50} offset={50}>
                  <img
                    // src="assets/img/logo-white-1x.png"
                    src="assets/logo/LogoValencia3.webp"
                    width="120"
                    alt="logo"
                    className="img-fluid"
                  />
                </LazyLoad>
              ) : (
                <LazyLoad height={50} offset={50}>
                  <img
                    src="assets/logo/LogoValencia3.webp"
                    width="120"
                    alt="logo"
                    className="img-fluid"
                  />
                </LazyLoad>
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <span className="ti-menu"> Mas info</span> */}
              <button
                type="button"
                name="registrybtnnav"
                id="registrybtnnav"
                className="btn masinfo-play-btn mr-3"
                onClick={handleOpen}
                // value="Mas info"
                // onClick={handleClickOpen}
              >
                Mas info
              </button>
            </button>
          </div>
        </nav>
        <ResponsiveDialog
          open={open}
          handleClose={handleClose}
          titleButton={"Enviar"}
          title={"Mas informacion"}
          subTitle={null}
        />
      </header>
    </>
  );
};

export default Navbar;
