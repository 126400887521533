import React from "react";
import "@justinribeiro/lite-youtube";
// import { useState } from "react";
// import ResponsiveDialog from "../modal/ResponsiveDialog";

export default function FeatureImgThree() {
  // const [open, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <section id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <span className="text-uppercase color-secondary sub-title">
                  Nuestro Metodo
                </span>
                <h2>
                  Conoce nuestro metodo de FSELL-PRO especializado en
                  neuroventas inmobiliarias
                </h2>
                <p>
                  Descubra cómo la tecnología revoluciona el sector inmobiliario
                  con nuestro sistema innovador de ventas digitales.
                  Simplificamos el proceso de comprar y vender propiedades,
                  ofreciendo una experiencia totalmente digital, segura y
                  eficiente.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <lite-youtube
                style={{ width: "100%", height: "315px", display: "block" }}
                title="Guia Valencia 2024"
                videoid="_T9eVHxlCIc"
              ></lite-youtube>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
